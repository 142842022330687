import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
import SEO from "../components/SEO"
import ReactMarkdown from "react-markdown"

const About = ({
  data: {
    allDatoCmsAbout: { nodes },
  },
}) => {
  const { title, info, image } = nodes[0]
  return (
    <Layout>
      <SEO title="About" description="About Daniela" />
      <section className="about-page">
        <div className="section-center about-center">
          <Image fixed={image.fixed} className="about-img" />
          <article className="about-text">
            <Title title={title} />
            <ReactMarkdown source={info} />
          </article>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsAbout {
      nodes {
        title
        info
        image {
          fixed {
            ...GatsbyDatoCmsFixed
          }
        }
      }
    }
  }
`

export default About
